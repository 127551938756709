import React from 'react';
import './Header.css';

const Header = (props) => {

  return (
    <div className="AppHeader">
      <h1>AIで本命チョコ判定</h1>
      <span>{props.host}</span>
    </div>
  );
}

export default Header;
