import * as actionTypes from '../utils/actionTypes';

const initialAppState = {
  selected: false,
  image: "",
  base64URL : "",
  formData: undefined,
  host: "https://valentine.boomin.yokohama",
  // host: "http://localhost:8808",
  apiresult:undefined,
  isLoading: false,
};

const mainreducer = (state=initialAppState, action) => {
  
  if (action.type === actionTypes.JUDGE_IMAGE) {
    // 判定ボタンの押下

    // gatherFormData(state, state.image.name, state.base64URL);
    var formData = new FormData();
    formData.append('param', 0);
    let imgdata = resizeImage(state.base64URL);
    // console.log("resized: " + imgdata);
  
    formData.append('image', imgdata);
    formData.append('name', state.image.name);
    console.log("formData: "+ formData);

    state.isLoading = true;

    let options = {
      mode: 'no-cors',
      method: 'post',
      crossDomain:true,
      // headers: {
      //   'content-type': 'multipart/form-data',
      //   "X-HTTP-Method-Override": "PUT"
      // },
      body: formData
      // body: {
      //     "image":imgdata,
      //     "name":state.image.name,
      //   }
    }
    // options.headers['X-HTTP-Method-Override'] = 'PUT';

    console.log("options", options);
    fetch( state.host + "/img", options)
    .then(response => response.json())
    .then(data => {
      console.log("data", data);
      state.apiresult = data;
      state.isLoading = false;
    })
    .catch(err => {
      console.error('Request failed', err)
    })    



    return {
      ...state,
      // selected: false,
      // file    : "",
      // base64URL : "",
    };
  } else if (action.type === actionTypes.DELETE_IMAGE) {
    // 画像削除ボタンの押下
    return {
      ...state,
      selected: false,
      image    : "",
      base64URL : "",
    };
  } else if (action.type === actionTypes.SELECT_IMAGE & action.etarget !== undefined ) {
    // 画像選択ボタンの押下

    let files = action.etarget.files;
    // console.log("action.etarget: ", action.etarget);

    if (checkExtension(files[0])) {
      return {
        ...state,
        selected: true,
        image    : files[0],
        base64URL : window.URL.createObjectURL(files[0]),
      };
    } else {
      console.warn("変です")
      return {
        ...state,
        selected: false,
        image    : "",
        base64URL : "",
      };
    }
  } else {
    return state;
  }
}

const gatherFormData = (state, iname, base64image) => {
  state.formData = new FormData();
  state.formData.append('param', 0);
  let imgdata = resizeImage(base64image);
  // console.log("resized: " + imgdata);

  state.formData.append('image', imgdata);
  state.formData.append('name', iname);
  console.log("formData: " + state.formData);
}

const resizeImage = (base64image) => {
  const MIN_SIZE = 32;
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  let image = new Image();
  image.src = base64image;
  canvas.width = MIN_SIZE;
  canvas.height = MIN_SIZE;
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, MIN_SIZE, MIN_SIZE);
  let sendimgdata = canvas.toDataURL();
  return sendimgdata;
}


const checkExtension = (image) => {
  let type = image.type.split('/')[0];
  if (type === "image") {
      return true
  } else {
      return false
  }
}

export default mainreducer;