import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const OnClickBtn = (prop) => {
  return (

    <label htmlFor={prop.name} className="form-label">{prop.title}
      <input
        className="form-input"
        id={prop.name}
        type={prop.type}
        name={prop.name}
        onClick={prop.onClick}
      />
    </label>
  )
}

OnClickBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

// const mapStateToProps = state => state.header;

export default OnClickBtn;