import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const OnChangeBtn = ({name, type, title, onChange}) => {
  return (
    <label htmlFor={name} className="form-label">{title}
      <input
        className="form-input"
        id={name}
        type={type}
        name={name}
        onChange={onChange}
      />
    </label>
  )
}

OnChangeBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default OnChangeBtn;
