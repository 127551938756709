import * as actionTypes from '../utils/actionTypes';

export const onJudgeImage = () => ({
  type: actionTypes.JUDGE_IMAGE,
});

export const onSelectImage = (etarget) => ({
  type: actionTypes.SELECT_IMAGE,
  etarget,
});

export const onDeleteImage = () => ({
  type: actionTypes.DELETE_IMAGE,
});

export const onNumClick = (number) => ({
  type: actionTypes.INPUT_NUMBER,
  number,
});

export const onPlusClick = () => ({
  type: actionTypes.PLUS,
});
