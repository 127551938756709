import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ValentineContainer.css';

import * as actions from '../actions';
import Header from '../components/Header/Header';
import OnChangeBtn from '../components/Button/OnChangeBtn';
import OnClickBtn from '../components/Button/OnClickBtn';
import Image from '../components/Image/Image';

class ValentineContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buttontitle:"Select Image file",
      file: "",
      URLstring: ""
    }
    this.handleImageFile = this.handleImageFile.bind(this);
  }

  /* This life cycle hook gets executed when the component mounts */

  handleImageFile(e) {
    let files = e.target.files;
    // console.log("value", files);

    // 拡張子の判定
    let iname = files[0].name;
    let type = iname.split('.');
    let ext = type[type.length - 1].toLowerCase();
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      // console.log(files[0])
      this.setState({
        file  : files[0],
        URLstring : URL.createObjectURL(files[0])
      })
    } else {
      console.warn("画像ファイルじゃないです")
    }

  }


  render() {
    // イベントハンドラを受け取っておく
    const { mainreducer, actions } = this.props;
    return (
      <div className="App">

        <Header />

        <div className="AppBody">

          <div className="form-group">

            { !mainreducer.selected ? 
              <OnChangeBtn 
                type={'file'}
                name= {"selectImg"}
                title= {this.state.buttontitle}
                onChange = {(e) => actions.onSelectImage(e.target)}
              /> : 
              null
            }

            { mainreducer.selected ? 
              <OnClickBtn 
                type={'reset'}
                name= {"deleteImg"}
                title= {'Delete Image File'}
                onClick = {() => actions.onDeleteImage()}
              /> : 
              null
            }

            { mainreducer.selected ? 
              <OnClickBtn
                type={'button'}
                name= {"judgeImg"}
                title= {'本命か義理か判定する'}
                onClick = {() => actions.onJudgeImage()}
                disabled={mainreducer.selected}
              /> : 
              null
            }

          </div>             

          <Image 
            src={mainreducer.base64URL}
          />

       

          {/* <SNS /> */}

        </div>

      </div>
    );
  }
}

const mapState = (state, ownProps) => ({
  mainreducer: state.mainreducer,
});

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(ValentineContainer);